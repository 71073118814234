.site-hero
	color: white;
	font-style: italic;
	height: 565px;
	overflow: hidden;
	margin-bottom: 0;
	position: relative;
	+below(bpMedium)
		height: 300px;
	// &:after
	// 	display: block;
	// 	content: '';
	// 	position: absolute;
	// 	z-index: 3;
	// 	top: 0;
	// 	right: 0;
	// 	left: 0;
	// 	bottom: 0;
	// 	background-color: rgba(black, 0.6);
	&__slideshow
		margin-bottom: 0!important;
		position: relative;
		z-index: 2;
		height: 100%;
		.slick-slide
			& > div
				height: 100%;
		.slide
			height: 100%;
			position: relative;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			img
				width: 100%;
				position: absolute;
				height: auto;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

	.wrapper
		position: absolute;
		z-index: 4;
		max-width: 640px;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	h1
		font-weight: $fwN;
	p
		font-weight: $fwL;
		font-size: 20px;
		margin: 0 0 20px 0;
		+below(bpMedium)
			display: none;
	.btn
		font-style: normal;

body.inner
	.site-hero
		height: 300px;
		+above(bpMedium)
			height: 500px;
		h1
			display: block;
			color: $cPrimary20;
			font-weight: $fwN;
			font-style: normal;
			text-transform: uppercase;
			letter-spacing: 12px;
			line-height: 1.2
			font-size: 30px;
			margin-top: 60px;
			+above(bpMedium)
				font-size: 60px;
		.slide
			height: 100%;
			position: relative;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
