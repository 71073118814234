// =============================================================================
// blockquote
// =============================================================================


blockquote, .blockquote
	padding: 10px 20px;
	margin: 0 0 20px;
	font-size: 17.5px;
	border-left: 5px solid $cBorder;
	footer
		display: block;
		font-size: 80%;
		line-height: $lHeightBase;
	&.blockquote-reverse
		padding-right: 15px;
		padding-left: 0;
		text-align: right;
		border-right: 5px solid $cBorder;
		border-left: 0;
