.section
	padding: 40px 0;
	+above(bpMedium)
		padding: 80px 0;
.section-title
	font-weight: $fwN;
	margin-bottom: 40px;
	+above(bpMedium)
		margin-bottom: 40px;
.section__footer
	margin-top: 0px;
	+above(bpMedium)
		margin-top: 40px;

.section.our-team
	text-align: center;
	.wrapper
		max-width: 860px;
		margin: 0 auto;
	.members
		clearfix();
		.member
			float: left;
			width: 100%;
			margin-bottom: 40px;
			+above(bpMedium)
				margin-bottom: 0;
				width: 33.33%;
				padding: 0 30px;
			.member-img
				margin-bottom: 10px;
			.name
				color: black;
				font-size: 16px;
				line-height: 1.1;
				margin-bottom: 0;
				font-weight: $fwSB;
			.position
				margin-bottom: 0;
				font-size: 14px;
				color: $cPrimary;

.section.our-team-full
	text-align: center;
	.members
		clearfix();
		.member
			float: left;
			width: 100%;
			margin-bottom: 40px;
			p
				margin 0
				padding 0
			+above(bpMedium)
				margin-bottom: 0;
				width: 33.33%;
				padding: 0 30px;
			.member-img
				margin-bottom: 10px;
			.name
				color: black;
				font-size: 16px;
				line-height: 1.1;
				margin-bottom: 0;
				font-weight: $fwSB;

			.position
				margin-bottom: 0;
				font-size: 14px;
				color: $cPrimary;
			.bio
				margin-top 20px
				p
					&:after
						display block
						content ''
						position relative
						height 20px
						width 100%

	.btn
		display: inline-block!important;
		margin 0
		padding 10px
	.bio__content[aria-expanded~=true]
		& + a
			box-shadow: none;


section.newsletter-form
	background-color: $cformFieldBg
	padding: 63px 0 60px 0
	.form-fields
		vertical-align: top
		.field
			display: inline-block
			&.title
				width: 100%
				text-align: center
				+above(bpMedium)
					width: 29%
			&.input
				width: 100%
				margin: 20px 0
				+above(bpMedium)
					margin: 0
					width: 50%
			&.submit
				width: 100%
				padding: 0 20px
				text-align: center
				+above(bpMedium)
					text-align: left
					width: 20%
				.btn
					display inline-block
					+above(bpMedium)
						margin: -3px 0 0 0
		.input
			input
				border-radius: 30px;
				padding: 13px 40px;
				background-color: white
				font-size: 14px
				margin: 0
				+above(bpMedium)
					font-size: 18px
		.title
			h2
				margin: 0
				padding: 0
				font-size: 18px
				font-weight: 300
				color: $cInfo



.section-featured
	position: relative;
	text-align: center;
	overflow: hidden;
	color: white;
	padding: 0 20px;
	width: 100%;
	height: 330px;
	background-color: transparent;
	+below(bpMedium)
		background-image: none!important;
	+above(bpMedium)
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		background-attachment: fixed!important;
		height: 550px;
	&:after
		display: block;
		content: '';
		position: absolute;
		z-index: 3;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background-color: rgba(black, 0.6);
	.section-featured__title
		position: relative;
		z-index: 4;
		margin: 0;
		font-style: italic;
		font-size: 40px;
		line-height: 1.3;
		font-weight: $fwN;
		position: absolute;
		width: 80%;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	img
		+below(bpMedium)
			width: 100%;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		+below(500px)
			transform: translateY(-50%) scale(1.8);
		+above(bpMedium)
			display: none;


.section-testimonials
	padding: 80px 0;
	text-align: center;
	background: transparent url('/images/featured/pacijenti_home.jpg') no-repeat top center;
	background-size: cover;
	.testimonials-slideshow
		margin-bottom: 0;
		height: auto;
	.slick-slide
		height: auto!important;
	.wrapper
		max-width: 740px;
	.testimonials-navigation
		position: relative;
		height: 60px;
		text-align: center;
	.testimonial__content
		font-style: italic;
		font-size: 20px;
		line-height: 1.2;
	.testimonial__author
		font-size: 20px;
		color: $cPrimary;





.section-partners
	padding: 50px 0;
	text-align: center;
	.wrapper__inner
		clearfix();
		+above(bpMedium)
			align-content: center;
			display: flex;
			align-items: center;
			justify-content: space-beetwen;
			flex-direction: column;
			flex-direction: row;
	a
		display: block;
		float: left;
		width: 100%;
		opacity: 0.5;
		text-align: center;
		allTransition();
		margin: 20px auto;
		+above(bpMedium)
			margin: 0 auto;
			float: none;
			flex: 1 0 0;
		img
			display: block;
			margin: 0 auto;
		&:hover, &:focus
			opacity: 1;


.section-text
	padding: 80px 0 0 0;
	text-align: center;
	p
		max-width: 900px
		margin: 20px auto;

.section-links
	text-align: center;
	.wrapper__inner
		clearfix();
	a:hover
		color: $cBlack;

.video
	padding-top: 40px;
	iframe
		display: block;
		max-width: 840px;
		width: 100%;
		margin: 0 auto;

.section-text-services
	.content
		clearfix();
		p:last-child
			margin-bottom: 0;
	.title-mobile
		display: none;
		+below(bpMedium)
			display: inline-block;
	.title-desktop
		display: inline-block;
		+below(bpMedium)
			display: none;
	.title
		font-size: 22px;
		line-height: 1.2;
		color: $cPrimary;
		text-transform: uppercase;
		margin: 0 0 40px 0;
		padding: 0 0 0 30px;
		font-weight: $fwSB;
		position: relative;
		&:after
			display: block;
			content: "\f067";
			font-family: "FontAwesome";
			font-weight: $fwN;
			color: $cPrimary;
			font-style: normal;
			position: absolute;
			left: 0;
			top: 3px;
			font-size: 22px;

	.subtitle
		display: block;
		clear:both;
		font-size: 16px;
		line-height: 1.2;
		margin: 0 0 20px 0;
		color: $cPrimary;
	p
		margin-bottom: 30px;
	img
		float: left;
		margin: 0 0 40px 0;
		width: 100%;
		+above(bpMedium)
			width: auto;
			margin: 0 40px 40px 0;

body.services
	.section
		padding: 80px 0;
	.section-text-services
		+above(bpMedium)
			padding: 100px 0 100px 0

body.team
	+below(bpMedium)
		.section
			padding: 40px 0;

body.aboutus
	+below(bpMedium)
		hr
			margin: 0;
		.section
			padding: 60px 0 60px 0;

.blog-listing
	padding: 80px 0;
	+above(bpMedium)
		padding: 120px 0;
	p
		margin 5px 0 10px 0
		+above(bpMedium)
			margin 10px 0 10px 0
	.content
		clearfix();
	.title-mobile
		display: none;
		+below(bpMedium)
			display: inline;
	.title-desktop
		display: inline;
		+below(bpMedium)
			display: none;
	.title
		font-size: 20px;
		line-height: 1.2;
		color: $cPrimary;
		text-transform: uppercase;
		margin: 0 0 40px 0;
		padding: 0 0 0 30px;
		font-weight: $fwSB;
		position: relative;
		&:after
			display: inline-block;
			content: "\f067";
			font-family: "FontAwesome";
			font-weight: $fwN;
			color: $cPrimary;
			font-style: normal;
			position: absolute;
			left: 0;
			top: 3px;
			font-size: 19px;
			allTransition()
	.subtitle
		display: block;
		clear:both;
		font-size: 16px;
		line-height: 1.2;
		margin: 0 0 20px 0;
		color: $cPrimary;
	// p
		// margin-bottom: 30px;
	.content
		p:last-child
			margin-bottom: 0;
		a
			color: $cPrimary;
			font-weight: $fwSB;
			&:hover
				color: $cDefault;
	.title
		color: $cPrimary;
		font-weight: $fwSB;
		&:hover
			color: $cDefault;
		&:hover:after
			color: $cDefault;


	img
		float: left;
		margin: 20px 0 20px 0;
		width: 100%;
		+above(bpSmall)
			width: auto;
			margin: 10px 40px 10px 0;
		+above(bpMedium)
			margin: 0 40px 10px 0;


body.blog-single
	.title
		color: $cPrimary;
		font-weight: $fwSB;
		&:hover
			color: $cPrimary;
		&:hover:after
			color: $cPrimary;
	ol
		margin 20px
		padding 0
		font-weight 700
		li
			font-weight normal
			margin-bottom 20px




img.pain_free
	display: block;
	margin: 80px auto 0 auto;



body.pricelist
	.section
		padding: 80px 0;
		+above(bpMedium)
			padding: 120px 0;


body.contact
	.contact-info
		a
			&:hover
				color: $cPrimary;
body.contact
	.section
		+below(bpMedium)
			padding: 80px 0;



