// =============================================================================
// FORMS
// =============================================================================



// Default styling
// ================

input[type="text"], input[type="email"], input[type="password"],input[type="search"], textarea
  box-sizing: border-box;
  appearance: none !important;
  position:relative;
  box-shadow: none;
  outline: none;
  border: 0;
  background-color: $cformFieldBg;
  font-size: 12px;
  font-family: $fSansBase;
  padding: 20px;
  color: black;
  font-weight: $fwSB;
  width: 100%;
  margin-bottom: 20px;
  &:focus { outline: none; }
  border-radius: 0;
  transition-property: background-color, border-color;
  transition-duration: 1s;

  placeholderColor = $cPlaceholder;
  &::-webkit-input-placeholder { color: $cPlaceholder; font-family: $fSansBase; font-weight: $fwSB;}
  &:-moz-placeholder { color: $cPlaceholder; font-family: $fSansBase; font-weight: $fwSB;}
  &::-moz-placeholder { color: $cPlaceholder; font-family: $fSansBase; font-weight: $fwSB;}
  &:-ms-input-placeholder { color: $cPlaceholder; font-family: $fSansBase; font-weight: $fwSB;}
  +above(bpMedium)
    font-size: 18px;

fieldset
  border: 0;
  margin: 0;
  padding: 0;
textarea {
  min-height: 180px;
  resize: none;
  transition-property: background-color, border-color;
  transition-duration: 1s;
}
label
	font-size: 14px;
	font-weight: $fwB;

// CUSTOM SELECT / CHECKBOX / RADIO
// ================

select, input[type="checkbox"], input[type="radio"]
  appearance(none);


// CONTACT FORM
// ================

.contact-form
  padding: 80px 0;
  &__header
    text-align: center;

  form
    max-width: 600px;
    margin: 0 auto;

  .btn
    display: block;
    width: 150px;
    margin: 0 auto;

form.fail
  .msg-fail
    display: block;

form.success
  .msg-success
    display: block;
    color: $cSuccess;

form.loading
  .btn
    display: none;
  .msg-loading
    display: block;
    color: $cSuccess;

.msg
  text-align: center;
  padding: 20px 0 0 0;
  font-weight: $fwSB;
  display: none;
  color: $cDanger;

