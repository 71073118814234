.choose-language
	position: relative;
	padding: 0 0 0 20px;
	display: table-cell;
	vertical-align: middle;
	text-transform: uppercase;
	width: 50px;
	height: 36px;
	border-radius: 4px;
	position relative


	ul,
	li
		text-transform uppercase
		list-style: none;
		display: block;
		// height: 40px;
		padding: 0;
		margin: 0;
		background-color: $cDefault;
	li
		text-align: center;
		display: block;
		&> a
			display: block;
			padding: 5px;
			line-height: 30px;
			font-weight: 600;
			color: white;
			&:hover
				background-color: #000;
	.selected-language
		width: 55px;
		height: 36px;
		text-align: left;
		background-color: $cDefault;
		border-radius: 2px 2px 2px 2px;
		position relative
		padding 0 15px 0 9px
		&:after
			display block
			content: "\f078"
			font-family "FontAwesome"
			position absolute
			top 10px
			right 8px
			font-size 9px
			color white
		a
			display: block;
			width: 100%;
			line-height: 37px;
			font-weight: 600;
			padding: 0;
			color: white;
		&.open
			border-radius: 4px 4px 0 0;
	ul
		display: none;
		position: absolute;
		z-index: 8;
		width: 55px;
		top: 56px;
		text-transform: none;
		border-radius:  0 0 4px 4px

		&.open
			display: block;
			animation: fadeInDropdown 0.7s;
			border-radius: 4px 4px 0 0;

	+above(bpMedium)
		display: block;
		display: table-cell;
		height: 100%;
		vertical-align: middle;
		text-transform: uppercase;
		padding: 0 0 0 20px;
		ul
			display: none;
			position: absolute;
			top: 76px;
			z-index: 8;
			text-transform: none;
		&:hover
			border-radius: 4px 4px 0 0;
			ul
				display: block;
				animation: fadeInDropdown 0.7s;







