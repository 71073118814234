$headerHeightMobile = 80px;
$headerHeight = 120px;

.site-logo
	display: block;
	width: 230px;
	height: 80px;
	ir();
	background: transparent url('images/logos/dentacentar3.png') no-repeat top left;
	background: transparent url('images/logos/dentacentar3.svg') no-repeat top left;
	background-size: contain;
	+above(bpMedium)
		background: transparent url('images/logos/dentacentar3.png') no-repeat top left;
		background: transparent url('images/logos/dentacentar3.svg') no-repeat top left;
		background-size: contain;
		margin-top: 10px;
	+below(bpMedium)
		width: 165px;
		height: 50px;

.site-header
	width: 100%;
	height: $headerHeight;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	+below(bpMedium)
		height: $headerHeightMobile;
	.wrapper
		height: 100%;
	.wrapper__inner
		display: table;
		height: 100%;
		width: 100%;
		.site-branding, .navigation-icon
			display: table-cell;
			height: 100%;
			vertical-align middle;
		.site-branding
			padding: 10px 0;
		.navigation-icon
			text-align: right;
		.site-navigation
			+above(bpMedium)
				display: table-cell;
				height: 100%;
				vertical-align middle;

	// =============================================================================
	// NAVIGATION
	// =============================================================================

.site-navigation
	text-transform: uppercase;
	+below(bpMedium)
		font-weight: $fwSB;
		background-color: white;
		font-size: 20px;
		position: fixed;
		top: $headerHeightMobile;
		left: 0;
		width: 100%;
		height: 0;
		overflow: hidden;
		text-align: center;
		margin-left: 0;
		allTransition()
		nav >ul
			width: 100%;
			// height: 100vh;
			display: block;
			overflow-x: scroll;
			margin: 0;
			padding: 0;
			text-align: left;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			&>li
				display: inline-block;
				&>a
					padding: 20px 20px;
					color: $cDefault;
					font-size: 15px;
					line-height: 1;
					display: block;
					allTransition()
					&.active, &:focus
						background-color: $cPrimary;
						color: white;
						text-decoration: none;
			&>li:nth-child(odd)
				background-color: darken(white, 10%);

	+above(bpMedium)
		font-weight: $fwSB;
		height: 100%;
		nav
			&>ul
				display: block;
				height:100%;
				margin: 0;
				padding: 0;
				text-align: right;
				&>li
					display: inline-block;
					&>a
						display: block;
						padding: 0 10px 0 10px;
						font-size: 15px;
						display: block;
						allTransition();
						&.active, &:hover, &:focus
							color: $cPrimary;
							text-decoration: none;

body.inner
	.site-logo
		background: transparent url('images/logos/dentacentar6.png') no-repeat top left;
		background: transparent url('images/logos/dentacentar6.svg') no-repeat top left;
		background-size: contain;
		+above(bpMedium)
			background: transparent url('images/logos/dentacentar6.png') no-repeat top left;
			background: transparent url('images/logos/dentacentar6.svg') no-repeat top left;
			background-size: contain;

	.site-navigation
		+above(bpMedium)
			nav>ul>li>a
				color: white;
				&:hover
					color: $cPrimary;




.hamburger
	+above(bpMedium)
		display: none;

body.home
	padding-top: $headerHeightMobile;
	+above(bpMedium)
		padding-top: 120px;
body.inner
	padding-top: 0;

body.nav-is-active
	.site-navigation
		height: calc(100vh - 80px);
		overflow: scroll;
		box-shadow: 1px 6px 6px rgba(0,0,0,0.3);

body.nav-is-active
	overflow: hidden;
	position: relative;
	.site-header__ta, .site-header__book, .site-header__fb
		opacity: 0;
		pointer-events: none;


.headroom
	transition: all 0.3s ease;
	&--not-top
		height: $headerHeightMobile!important;
		background-color: $cFooterBg;
		+above(bpMedium)
			height: $headerHeight!important;
	&--pinned
		transform: translate(0, 0);
		background-color: $cFooterBg;
	&--unpinned
		transform: translate(0, -100%);
	&--top
		background-color: transparent;

body.home
	.headroom--pinned,
	.headroom--not-top
		background-color: white;




// Active links styling
body.home .site-navigation a.home,
body.aboutus  .site-navigation a.aboutus,
body.team .site-navigation a.team,
body.examination  .site-navigation a.examination,
body.services .site-navigation a.services,
body.pricelist  .site-navigation a.pricelist,
body.blog .site-navigation a.blog,
body.contact  .site-navigation a.contact
	color: $cPrimary;
