.table
	background-color: transparent;
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;
	caption
		padding-top: 10px;
		padding-bottom: 10px;
		color: $cTextColor;
		text-align: left;
	th
		text-align: left;
	& > thead > tr > th,
	& > tbody > tr > th,
	& > tfoot > tr > th,
	& > thead > tr > td,
	& > tbody > tr > td,
	& > tfoot > tr > td
		padding: 20px 0;
		line-height: $lHeightBase;
		vertical-align: top;
		border-top: 1px solid $cBorder;
	& > thead > tr > th
		vertical-align: bottom;
		border-bottom: 2px solid $cBorder;
