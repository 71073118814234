.site-footer-logo
	ir();
	display: inline-block;
	width: 177px;
	height: 106px;
	background: transparent url('/images/logos/dentacentar5.png') no-repeat top left;
	background: transparent url('/images/logos/dentacentar5.svg') no-repeat top left;
	background-size: contain;

.site-footer
	padding: 80px 0 40px 0;
	background-color: $cFooterBg
	color: white;
	font-style: italic;
	font-size: 12px;
	+above(bpMedium)
		padding: 80px 0 120px 0;
	a
		&:hover
			color: $cPrimary10;
	ul
		margin: 0;
		padding: 0;
		list-styl-type: none;
		li
			display: block;
	address
		line-height: 27px;
		margin-bottom: 20px;
	.wrapper__inner
		clearfix();
	.footer-section
		float: left;
		width: 100%;
		+below(bpMedium)
			margin-bottom: 20px;
		+above(bpMedium)
			width: 15%;
	.footer-section.logo
		+above(bpMedium)
			width: 25%
	.footer-section.sitemap
		+above(bpMedium)
			width: 15%;
		li
			padding: 5px 0;
		a
			text-transform: capitalize;
			display: block;
			padding: 0 0 5px 10px;
			line-height: 1;
			position: relative;
			&:before
				display: block;
				content: '+';
				position: absolute;
				left: 0;
				top: 1;
		+below(bpMedium)
			display: none;


	.footer-section.copyright
		+above(bpMedium)
			width: 30%;
			text-align: right;
		i
			display: inline-block;
			margin: 0 5px;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
		p:nth-child(2)
			margin-bottom: 20px;
		span
			color: $cPrimary10;

	.footer-section.social
		p
			margin-bottom: 0
		a
			margin: 0 5px 0 0;
			text-align: center;
			width: 35px;
			height: 35px;
			display: inline-block;
			background-color: $cDefault;
			padding: 8px;
			border-radius: 50%;
			color: $cFooterBg;
			&:hover
				color: $cPrimary10;
		i
			font-size: 20px;
		.networks
			margin: 10px 0 20px 0;

	.footer-section-title
		font-style: normal;
		font-size: 12px;
		color: $cPrimary10;
		font-weight: $fwSB;
		margin: 0 0 10px 0!important;


