// =======================
// Mixins / Functions /
// =======================

// Baseline grid
// =======================
units($n)
	$n *2/3 * $lHeightBase;
lines($n)
	$n * $lHeightBase;


linkTransition()
	transition: color 0.3s ease, border-color 0.3s ease;
allTransition()
	transition: all 0.3s ease;

// Background image
bgimg($size)
	background-size: $size;
	background-position: center;
	background-repeat: no-repeat;

// Image replacement
ir()
	font: 0/0 a;
	text-shadow: none;
	color: transparent;

// Semantic clearfix
// Usage .element{ clearfix() }
clearfix()
	&:before,
	&:after
		content: "";
		display: table;
		clear: both;

// Row machine (margin in percentages!)
rowMachine(numPerRow, margin)
	width: ((100% - ((numPerRow - 1) * margin)) / numPerRow);
	margin-bottom: margin;
	margin-right: margin;
	&:nth-child({numPerRow}n)
		margin-right: 0;

appearance(value)
	-webkit-appearance: value
	-moz-appearance: value
	-ms-appearance: value
	-o-appearance: value
	appearance: value
