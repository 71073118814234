.section.contact-info
	text-align: center;

.contact-info__social
	margin: 80px 0 0 0;
	a
		margin: 0 5px 0 0;
		text-align: center;
		width: 35px;
		height: 35px;
		display: inline-block;
		background-color: $cDefault;
		padding: 8px;
		border-radius: 50%;
		color: white;
		line-height: 1;
		&:hover
			color: $cPrimary10;
	i
		font-size: 20px;

.company-info
	font-weight: $fwL;
	font-size: 14px;
	p
		margin: 0 0 30px 0;
	p:last-child
		margin: 0;

