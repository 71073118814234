// =============================================================================
// LISTS
// =============================================================================


dl
	margin: 60px 0;
dd
	margin: 0 0 40px 0;

dt
	font-weight: $fwB;
	color: $cPrimary;

ul.styled-list-large
	padding: 0;
	list-style: none;
	max-width: 640px;
	margin: 10px auto;
	text-align: left;

	li
		padding: 5px 5px 5px 40px;
		position: relative;
		>a
			font-weight: $fwSB;
			line-height: 1.2;
			color: $cPrimary;
			font-size: 20px;
			+above(bpMedium)
				font-size: 22px;
		&:after
			allTransition();
			display: block;
			content: "\f067";
			font-family: "FontAwesome";
			font-weight: $fwN;
			color: $cPrimary;
			font-style: normal;
			position: absolute;
			left: 0;
			top: 5px;
			font-size: 20px;
			+above(bpMedium)
				font-size: 22px;
		&:hover
			a
				color: $cDefault;
			&:after
				color: $cDefault;
