.cookie-notice
  bottom: 20px
  right: 20px
  left: 20px
  width: auto
  height: auto
  background-color: #f1572a
  padding: 20px 10px
  border-radius: 4px
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3)
  text-align: center
  color: white
  font-size: 14px

  @media (--md)
    font-size: 16px

  a
    color: black
    display: inline-block

  .actions
    display: block
    clear: both
    text-align: center

    @media (--sm)
      display: inline-block

  img
    display: inline-block
    width: 20px
    vertical-align: middle
    margin-right: 5px

    @media (--md)
      margin-right: 10px
      width: 30px

  button
    display: inline-block
    margin-top: 10px
    margin-right: 15px
    cursor: pointer
    border: 0
    padding: 12px 10px 11px 10px
    min-width: 50px
    line-height: 1
    text-transform: uppercase
    color: white
    border-radius: 4px

    &.ok-button
      background-color: white
      width: 80px
      color: #f1572a
      font-size: 14px

    &.optout-button
      font-size: 12px
      color: black
      background-color transparent

