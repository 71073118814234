// =============================================================================
// FLEXBOX GRID
// =============================================================================

/*  FLEXBOX CHEATSHEET
 flex: none                                             value 'none' case
 flex: <'flex-grow'>                                    One value syntax, variation 1
 flex: <'flex-basis'>                                   One value syntax, variation 2
 flex: <'flex-grow'> <'flex-basis'>                     Two values syntax, variation 1
 flex: <'flex-grow'> <'flex-shrink'>                    Two values syntax, variation 2
 flex: <'flex-grow'> <'flex-shrink'> <'flex-basis'>     Three values syntax
 flex: inherit
*/

.row
	display:flex
.col
	flex:1

+below(bpMedium)
	.row
		flex-direction:column
	.col
		flex:0 0 auto

+above(bpMedium)
	.col-tenth
		flex:0 0 10%
	.col-fifth
		flex:0 0 20%
	.col-quarter
		flex:0 0 25%
	.col-third
		flex:0 0 33.3333334%
	.col-half
		flex:0 0 50%

