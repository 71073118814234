// =============================================================================
// BUTTONS
// =============================================================================
.btn
	display: inline-block;
	padding: 15px 30px;
	margin: 5px;
	font-size: 14px;
	font-weight: $fwSB;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	user-select: none;
	background-image: none;
	border: 3px solid transparent;
	border-radius: 8px;
	color: white;
	background-color: white;
	transition: all 0.25s;
	&:focus
		outline: none;
	&--block
		display: block;
		width: 100%;
	&--link
		border-color: transparent;
		background-color: transparent;
		color: $cPrimary;
		box-shadow: 0px -20px 78px 60px rgba(255,255,255,0.9);
		&:hover
			color: $cPrimary;
	&--default
		color: white;
		border-color: $btnDefault;
		background-color: $cbtnDefault;
		&:hover, &:focus
			border-color: $cbtnDefault;
			color: $cbtnDefault;
			background-color: transparent;
	&--primary
		color: white;
		border-color $cPrimary;
		background-color: $cPrimary;
		&:hover, &:focus
			color: $cPrimary;
			background-color transparent;

