/* Slider */

.slick-slider {
		position: relative;
		display: block;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
		margin-bottom: 0;
}
.slick-list {
		position: relative;
		overflow: hidden;
		display: block;
		margin: 0;
		padding: 0;
		height: 100%;

		&:focus {
				outline: none;
		}

		&.dragging {
				cursor: pointer;
				cursor: hand;
		}
}
.slick-slider .slick-track,
.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
}

.slick-track {
		position: relative;
		left: 0;
		top: 0;
		display: block;
		height: 100%;

		&:before,
		&:after {
				content: "";
				display: table;
		}

		&:after {
				clear: both;
		}

		.slick-loading & {
				visibility: hidden;
		}
}
.slick-slide {
		float: left;
		height: 100%;
		min-height: 1px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
    padding: 0;
		&:focus{
			outline: none;
			box-shadow: none;
		}
		[dir="rtl"] & {
				float: right;
		}
		img {
				display: block;
		}
		&.slick-loading img {
				display: none;
		}

		display: none;

		&.dragging img {
				pointer-events: none;
		}

		.slick-initialized & {
				display: block;
		}

		.slick-loading & {
				visibility: hidden;
		}

		.slick-vertical & {
				display: block;
				height: auto;
				border: 1px solid transparent;
		}
}
.slick-arrow.slick-hidden {
		display: none;
}


// Default Variables

$slick-font-path= "/fonts/";
$slick-font-family= "FontAwesome";
$slick-loader-path= "./";
$slick-arrow-color= $cDefault;
$slick-dot-color= $cDefault;
$slick-dot-color-active= $slick-dot-color;
$slick-prev-character= "\f104";
$slick-next-character= "\f105";
$slick-dot-character= "•";
$slick-dot-size= 30px;
$slick-opacity-default= 0.3;
$slick-opacity-on-hover= 0.9;
$slick-opacity-not-active= 1;


/* Slider */

.slick-list {
		.slick-loading & {
				background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
		}
}

/* Icons */

@font-face {
		font-family: "slick";
		src: slick-font-url("/fonts/slick.eot");
		src: slick-font-url("/fonts/slick.eot?#iefix") format("embedded-opentype"), slick-font-url("/fonts/slick.woff") format("woff"), slick-font-url("/fonts/slick.ttf") format("truetype"), slick-font-url("/fonts/slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
}

/* Arrows */

.slick-prev,
.slick-next {
		// position: absolute;
		display: inline-block!important;
		height: 60px;
		width:60px;
		line-height: 0px;
		font-size: 0px;
		cursor: pointer;
		background: transparent;
		color: transparent;
		// top: 50%;
		margin-top: -10px\9; /*lte IE 8*/
		// -webkit-transform: translate(0, -50%);
		// -ms-transform: translate(0, -50%);
		// transform: translate(0, -50%);
		padding: 0;
		border: none;
		outline: none;
		opacity: 0.9;
		allTransition();
		&:hover{
				outline: none;
				background: transparent;
				color: transparent;
				opacity: 1;
				&:before {
						opacity: $slick-opacity-on-hover;
				}
		}
		&.slick-disabled:before {
				opacity: $slick-opacity-not-active;
		}
}

.slick-prev:before, .slick-next:before {
		font-family: $slick-font-family;
		font-size: 50px;
		line-height: 1;
		color: $slick-arrow-color;
		opacity: $slick-opacity-default;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
}

.slick-prev {
		left: 40%;
		// background: url('/images/arrow-prev.png') no-repeat center;
		// background-size: contain;
		[dir="rtl"] & {
				// left: auto;
				// right: -25px;
		}
		&:before {
				content: $slick-prev-character;
				font-style: normal;
				[dir="rtl"] & {
						content: $slick-next-character;
				}
		}
}

.slick-next {
		right: 40%;
		// background: url('/images/arrow-next.png') no-repeat center;
		// background-size: contain;
		[dir="rtl"] & {
				// left: -25px;
				right: auto;
		}
		&:before {
				content: $slick-next-character;
				font-style: normal;
				[dir="rtl"] & {
						content: $slick-prev-character;
				}
		}
}

/* Dots */

.slick-slider {
		margin-bottom: 30px;
}

.slick-dots {
		position: absolute;
		bottom: 0;
		list-style: none;
		display: block;
		text-align: center;
		padding: 0;
		width: 100%;
		margin: 0;
		li {
				position: relative;
				display: inline-block;
				height: 20px;
				width: 20px;
				margin: 0px;
				padding: 0;
				cursor: pointer;
				button {
						border: 0;
						background: transparent;
						display: block;
						height: 20px;
						width: 20px;
						outline: none;
						line-height: 0px;
						font-size: 0px;
						color: transparent;
						padding: 5px;
						cursor: pointer;
						&:hover, &:focus {
								outline: none;
								&:before {
										opacity: $slick-opacity-on-hover;
								}
						}
						&:before {
								position: absolute;
								top: 0;
								left: 0;
								content: $slick-dot-character;
								width: 20px;
								height: 20px;
								font-family: $slick-font-family;
								font-size: $slick-dot-size;
								line-height: 20px;
								text-align: center;
								color: $slick-dot-color;
								opacity: $slick-opacity-default;
								-webkit-font-smoothing: antialiased;
								-moz-osx-font-smoothing: grayscale;
						}
				}
				&.slick-active button:before {
						color: $slick-dot-color-active;
						opacity: $slick-opacity-on-hover;
				}
		}
}
