.services-and-blog
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#db542c+0,db542c+49,f1572a+50,f1572a+100 */
	background: #db542c; /* Old browsers */
	background: -moz-linear-gradient(left,  #db542c 0%, #db542c 50%, #f1572a 50%, #f1572a 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  #db542c 0%,#db542c 50%,#f1572a 50%,#f1572a 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  #db542c 0%,#db542c 50%,#f1572a 50%,#f1572a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	.wrapper__inner
		padding: 0;
		clearfix();
	.column
		color: white;
		float: left;
		width: 100%;
		+above(bpMedium)
			width: 50%;
		.services-list
			clearfix();
		ul
			padding: 0;
			list-style: none;
			margin: 10px 0;
			+below(bpSmall)
				margin: 0;
			+above(bpSmall)
				float: left;
				width: 50%;
			li
				padding: 5px 5px 5px 20px;
				position: relative;
				>a
					font-weight: $fwSB;
				&:after
					allTransition();
					display: block;
					content: "\f067";
					font-family: "FontAwesome";
					font-weight: $fwN;
					font-style: normal;
					position: absolute;
					left: 0;
					top: 6px;
				&:hover
					a
						color: $cDefault;
					&:after
						color: $cDefault;
						// transform: rotate(180deg);
	.column.services
		background-color: $cPrimary20;
		padding: 80px 20px;
		+above(bpMedium)
			padding: 80px 50px 80px 20px;
	.column.blog
		background-color: $cPrimary;
		padding: 80px 20px;
		+above(bpMedium)
			padding: 80px 20px 80px 50px;
		a.txt-link
			display: block;
			font-weight: 600;
			&:hover
				color: $cDefault;
		a
			display: inline-block

		img
			float: left;
			display block
			width 240px
			margin: 7px 20px 20px 0;
			+below(540px)
				width 100%
	.column-title
		text-transform: uppercase;
	.column__footer
		clearfix();
		margin-top: 20px;
		text-align: left;
		a
			margin: 0;
