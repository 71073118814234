// =============================================================================
// TYPOGRAPHY
// =============================================================================


// Headings
// =============================================================================
h1, h2, h3, h4, h5, h6
	font-family: $fontSansBase;
	margin: 0 0 20px 0;
h6
	font-size: 12px
h5
	font-size: 14px
h4
	font-size: 18px
h3
	font-size: 30px
h2
	font-size: 30px
h1
	font-size: 36px



// Inline elements
// =============================================================================
.small, small
	font-size: 85%;

.mark, mark
	padding: 10px;
	background-color: $cMarkHighlight;

s, strike, del
	text-decoration: line-through;

u,ins
	text-decoration: underline;

.decor-title
	clearfix();
	display: inline-block;
	width: auto;
	font-weight: $fwSB;
	position: relative;
	padding: 0 0 0 30px;
	text-align: center;
	line-height: 1.2;
	color: $cPrimary;
	font-size: 20px;
	+above(bpMedium)
		font-size: 22px;
	&:after
		display: block;
		content: "\f067";
		font-family: "FontAwesome";
		font-weight: $fwN;
		color: $cPrimary;
		font-style: normal;
		position: absolute;
		left: 0;
		top: 3px;
		font-size: 20px;
		+above(bpMedium)
			font-size: 22px;
